// These also need to be changed in ~Base/_variables.scss if modified.
const speedInMs = {
	veryFast: 200,
	fast: 300,
	medium: 500,
	slow: 800,
	verySlow: 1000,
	glacial: 3000,
};

/**
 * Returns speed in milliseconds.
 * 
 * @param speed - Either a Sass variable name or a number.
 * @returns The speed in milliseconds. Will be 10 if a preference for reduced animation is detected.
 */
export function getSpeed(speed: 'veryFast' | 'fast' | 'medium' | 'slow' | 'verySlow' | 'glacial' | number): number {
	if (prefersReducedAnimation() === true) {
		return 10;
	} else if (typeof speed === 'string') {
		return speedInMs[speed];
	} else {
		return speed;
	}
}

/**
 * Check user's animation preference.
 * 
 * @returns true if user prefers reduced animation and false if they want ALL the movement. 
 */
export function prefersReducedAnimation(): boolean {
	var browserPref: boolean;

	try {
		browserPref = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
	} catch (err) {
		browserPref = false;
		console.log('Browser too old to determine animation preferences.');
	}

	return browserPref;
}