// Node imports
import $ from 'jquery';

/**
 *
 * @param identifier
 */
export function convertToElement(identifier) {
	if (typeof $ !== 'undefined' && identifier instanceof $) {
		return identifier.get([0]);
	} else if (identifier instanceof NodeList) {
		return identifier[0];
	} else if (typeof identifier === 'string') {
		return document.querySelector(identifier);
	} else if (identifier instanceof Element) {
		return identifier;
	}
}

/**
 *
 * @param identifier
 */
export function convertToListOfElements(identifier) {
	const els = [];

	if (typeof $ !== 'undefined' && identifier instanceof $) {
		identifier.each(function() {
			els.push($(this).get([0]));
		});
	} else if (identifier instanceof NodeList) {
		for (var i = 0; i < identifier.length; i++) {
			els.push(identifier[i]);
		}
	} else if (typeof identifier === 'string') {
		const query = document.querySelectorAll(identifier);

		for (var j = 0; j < query.length; j++) {
			els.push(query[j]);
		}
	} else if (identifier instanceof Element) {
		els.push(identifier);
	}

	return els;
}

export function debug() {
	document.onkeydown = checkKey;

	function checkKey(e) {
		e = e || window.event;

		if (e.keyCode == '38') {
			// up arrow
			//console.log(Sitemap.getAllSections());
		} else if (e.keyCode == '40') {
			// down arrow
		} else if (e.keyCode == '37') {
			// left arrow
		} else if (e.keyCode == '39') {
			// right arrow
		}
	}
}

export function generateUniqueId(): string {
    // desired length of Id
    var idStrLen = 32;
    // always start with a letter -- base 36 makes for a nice shortcut
    var idStr = (Math.floor(Math.random() * 25) + 10).toString(36) + '_';
    // add a timestamp in milliseconds (base 36 again) as the base
    idStr += new Date().getTime().toString(36) + '_';
    // similar to above, complete the Id using random, alphanumeric characters
    do {
        idStr += Math.floor(Math.random() * 35).toString(36);
    } while (idStr.length < idStrLen);

    return idStr;
}

export function isPreviewMode() {
    if ($('body').attr('data-preview-mode') === 'true') {
        return true;
    } else {
        return false;
    }
}