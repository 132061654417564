// Node imports
import $ from 'jquery';

import * as Dialog from './Willis/Dialog';

function inputFocus() {
	const $body = $('body');

	$(document).on('focus', 'input', function() {
		$body.addClass('hideFooter');
	}).on('blur', 'input', function() {
		$body.removeClass('hideFooter');
	});
}

function formValidationDialog() {
	if ($('.error,.validation-summary-errors li').length > 0) {
		const openingLine = '<p>Please address the following issues:</p>';
		const closingLine = '<p>When you are done, please resubmit this form.</p>';
		var errorList = '<ul>';
		$('.error,.validation-summary-errors li').each(function() {
			if($(this).text() !== ''){
				errorList = errorList + '<li>' + $(this).text() + '</li>';
			}
		});
		errorList = errorList + '</ul>';
		Dialog.create('Required information was not properly submitted.', openingLine + errorList + closingLine);
	}
}

function infoBtn(){
	if ($('.formWrap').hasClass('userAccount')) {
		$('button.infoBtn').click(function (event) {
			if ($(this).is(':focus')) {
				event.preventDefault();
				Dialog.create('Please keep in mind the following.', 'If this field is changed, Password and Retype Password is required.');
			} else {
				event.preventDefault();
			}
		});
		if ($('.fieldWrapper.studentID').length) {
			$('.fieldWrapper.email').find('button.infoBtn').remove();
			$('.fieldWrapper.studentID').find('button.infoBtn').attr('aria-label', 'Information about the ID field');
		}
	}
}

formValidationDialog();
inputFocus();
infoBtn();
