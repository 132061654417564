// Node imports
import $ from 'jquery';

import * as Animation from './Animation';
import * as Util from './Util';

interface DialogOptions {
	closeDialogOnBackdropClick?: boolean;
	closeDialogOnEsc?: boolean;
	style?: 'modal' | 'largeModal' | 'shade' | 'full' | 'sidebarLeft' | 'sidebarRight' | 'tool' | 'custom';
	actionLoc?: 'top' | 'bottom' | 'custom';
	actionText?: string;
	animationName?: string;
	animationSpeed?: string;
}

interface CreateDialogOptions extends DialogOptions {
	customId?: string;
}

interface Dialog {
	id: string;
	title: string;
	originalContent: string;
	focusAfterClosed: HTMLElement;
	el: HTMLElement | null;
	firstFocusableDescendant: HTMLElement | null;
	lastFocusableDescendant: HTMLElement | null;
	backdrop: HTMLElement | null;
	zIndex: number;
	closeEvent: Function | null;
	options: any;
}

const defaults = {
	closeDialogOnBackdropClick: true,
	closeDialogOnEsc: true,
	style: 'modal',
	actionLoc: 'bottom',
	actionText: 'Okay',
	animationName: 'fade',
	animationSpeed: 'fast',
};

const openDialogList = [];
const idNumForBlankDialog = 100000;

/**
 * Accessible dialog boxes. Note: any events added to elements will be removed
 * when the dialog is opened or closed. To get around this, always add your events
 * once the dialog has been opened with a callback function.
 * @param id - The id of the element.
 * @param options - An object containing various options for how the dialog should be displayed, animate in, etc.
 */
export function open(id: string, options: DialogOptions, callback = null) {
	const originalEl = document.querySelector(`#${id}`);

	// Validate input
	if (!originalEl.hasAttribute('data-dialog-title')) {
		throw `Error: ${id} must have a "data-dialog-title" attribute!`;
	}

	if (originalEl.tagName !== 'WILLIS-DIALOG') {
		throw `Error: #${id} must have an tag name of 'willis-dialog' in order to be opened as a dialog`;
	}

	if (options.actionLoc === 'custom') {
		if (originalEl.querySelector('button.closeDialog') === null) {
			throw `Error: actionLoc param was set to 'custom' for dialog #${id}. It must contain a button with the class '.closeDialog'!`;
		}
	} else {
		if (originalEl.querySelector('.dialogActions') !== null) {
			throw `Error: dialog #${id} must not contain an element with the class 'dialogActions'!`;
		}

		if (originalEl.querySelector('.closeDialog') !== null) {
			throw `Error: dialog #${id} must not contain a button with the class '.closeDialog'!`;
		}
	}

	const dialog: Dialog = {
		id: id,
		title: document.querySelector(`#${id}`).getAttribute('data-dialog-title'),
		originalContent: originalEl.outerHTML,
		//focusAfterClosed: convertToDOMNode(focusAfterClosed),
		focusAfterClosed: <HTMLElement>document.activeElement,
		el: null,
		firstFocusableDescendant: null,
		lastFocusableDescendant: null,
		backdrop: null,
		zIndex: openDialogList.length + 500,
		closeEvent: null,
		options: Object.assign({}, defaults, options),
	};

	const classes = originalEl.getAttribute('class') ? originalEl.getAttribute('class') : '';
	const $dialogContent = $(`#${id}`).children().clone(true);
	var containsText = false;
	$dialogContent.each(function () {
		if ($(this).is('p') === true)
			containsText = true;
	});
	const content = generateDialogContent(dialog, classes, containsText);

	$(`#${id}`).replaceWith(content);
	$dialogContent.appendTo(`#${id} .dialogContent`);

	dialog.el = document.querySelector(`#${id}`);
	dialog.backdrop = document.querySelector(`div[data-backdrop-for="${id}"]`);
	dialog.firstFocusableDescendant = getFirstFocusableDescendant(dialog.el);
	dialog.lastFocusableDescendant = getLastFocusableDescendant(dialog.el);
	openDialogList.push(dialog);

	setBodyDialogAttr();
	setEventsAndAnimation(dialog, callback);
}

export function create(title, text, options?: CreateDialogOptions, callback = null) {
	var simpleId = `simpleDialog${idNumForBlankDialog}`;
	if (options && options.customId) {
		simpleId = options.customId;
	}
	const dialog: Dialog = {
		id: simpleId,
		title: title,
		originalContent: null,
		focusAfterClosed: <HTMLElement>document.activeElement,
		el: null,
		firstFocusableDescendant: null,
		lastFocusableDescendant: null,
		backdrop: null,
		zIndex: openDialogList.length + 500,
		closeEvent: null,
		options: Object.assign({}, defaults, options),
	};

	const classes = 'simpleDialog';
	const content = generateDialogContent(dialog, classes, false);

	$('body').append(content);
	$(`#${dialog.id} .dialogContent`).append(text);
	dialog.el = document.querySelector(`#${dialog.id}`);
	dialog.backdrop = document.querySelector(`div[data-backdrop-for="${dialog.id}"]`);
	dialog.firstFocusableDescendant = getFirstFocusableDescendant(dialog.el);
	dialog.lastFocusableDescendant = getLastFocusableDescendant(dialog.el);
	openDialogList.push(dialog);

	setBodyDialogAttr();
	setEventsAndAnimation(dialog, callback);
}

/**
 * Close the current dialog.
 * @param callback
 */
export function closeCurrent(callback = null) {
	const current = getCurrentDialog();
	close(current.id, null, callback);
}

/**
 * Closes all dialogs.
 * @param callback
 */
export function closeAll(callback = null) {
	// All the recursive functionality for actually closing multiple dialogs is contained
	// within the close() function, which is probably gross. but oh well.
	close(null, null, function () {
		callback();
	});
}

/**
 *
 * @param id - The id if the dialog. If this property isn't passed, all dialogs will be closed.
 * @param focusOverride
 * @param callback
 */
export function close(id = null, focusOverride = null, callback = null) {
	//var dialogsAreLeft = (openDialogList.length > 0);

	if (openDialogList.length > 0) {
		const closeAll = id === null ? true : false;

		var dialog;

		if (id === null) {
			dialog = getCurrentDialog();
		} else {
			dialog = getOpenDialogById(id);
		}
		dialog.el.setAttribute('data-dialog-animation-name', `${dialog.options.animationName}Out`);

		setTimeout(function () {
			dialog.backdrop.classList.add('out');
			setTimeout(function () {
				dialog.backdrop.classList.remove('out');

				const $changedContent = $(dialog.el).find('.dialogContent').children().clone();

				if (dialog.originalContent !== null) {
					$(dialog.el).parent().replaceWith(dialog.originalContent);
					$(`#${dialog.id}`).html('');
					$changedContent.appendTo(`#${dialog.id}`);
				} else {
					$(dialog.el).parent().remove();
				}

				if (dialog.closeEvent !== null) {
					dialog.closeEvent();
				}

				if (!closeAll) {
					if (focusOverride === null) {
						dialog.focusAfterClosed.focus();
					} else {
						Util.convertToElement(focusOverride).focus();
					}
				}

				removeOpenDialogById(dialog.id);
				setBodyDialogAttr();

				if (openDialogList.length > 0 && closeAll) {
					close(null, null, callback);
				} else {
					if (openDialogList.length < 1) {
						document.removeEventListener('focus', trapFocus, true);
						document.removeEventListener('keydown', checkForEscapeKey, true);
					}

					if (typeof callback === 'function') {
						callback();
					}
				}
			}, Animation.getSpeed('veryFast'));
		}, Animation.getSpeed(dialog.options.animationSpeed));
	} else {
		if (typeof callback === 'function') {
			callback();
		}
	}
}

/**
 * Returns a callback whenever a given dialog is closed.
 * @param id - The ID of the dialog.
 * @param callback - Initiated whenever the user (or the programmer I guess) closes the dialog.
 *
 */
export function onClose(id, callback) {
	const i = getOpenDialogIndexById(id);
	openDialogList[i].closeEvent = callback;
}

function setBodyDialogAttr() {
	if (openDialogList.length > 0) {
		document.querySelector('html').setAttribute('data-active-dialog', getCurrentDialog().id);
	} else {
		document.querySelector('html').setAttribute('data-active-dialog', '');
	}
}

function checkForEscapeKey(event) {
	if (event.key === 'Escape') {
		// escape key maps to keycode `27`
		if (getCurrentDialog().options.closeDialogOnEsc === true) {
			getCurrentDialog().el.querySelector('button.closeDialog').click();
		}
	}
}

function trapFocus(evt) {
	const dialog = getCurrentDialog();

	if (!dialog.el.contains(evt.target)) {
		const bouncerPos = document.activeElement.getAttribute('data-position');

		if (bouncerPos === 'start') {
			dialog.lastFocusableDescendant.focus();
		} else {
			dialog.firstFocusableDescendant.focus();
		}
	}
}

function generateDialogContent(dialog: Dialog, classes, containsText) {
	var describedBy = '';

	if (containsText === true) {
		describedBy = `aria-describedby="${dialog.id}_desc"`;
	}

	const inner = `
        <div class="dialogInner">
            <div id="${dialog.id}_label" class="dialogLabel" tabindex="-1">
                <h2>${dialog.title}</h2>
            </div>
			${dialog.options.actionLoc === 'top' ? generateDialogActions(dialog.options.actionText) : ''}
            <div id="${dialog.id}_desc" class="dialogContent">
            </div>
            ${dialog.options.actionLoc === 'bottom' ? generateDialogActions(dialog.options.actionText) : ''}
        </div>
    `;

	return `
        <div class="dialogBackdrop" data-dialog-style="${dialog.options.style}" data-backdrop-for="${dialog.id}" style="z-index: ${dialog.zIndex};">
            <div class="bouncer" data-position="start" tabindex="0"></div>
            <div id="${dialog.id}" class="${classes}" role="dialog" ${describedBy} aria-labelledby="${dialog.id}_label" aria-modal="true" data-dialog-animation-speed="${dialog.options.animationSpeed}">
                ${inner}
            </div>
            <div class="bouncer" data-position="end" tabindex="0"></div>
        </div>
    `;
}

function generateDescribedBy(id) {
	return `aria-described-by="${id}"`;
}

function generateDialogActions(actionText) {
	return `
        <div class="dialogActions">
            <button class="closeDialog">
                <span aria-hidden="true" class="icon"></span>
                <span class="text">${actionText}</span>
            </button>
        </div>
    `;
}

/**
 * Recursively search a given element for its first focusable element
 * and set firstFocusableDescendant to that element.
 * @param element
 */
function getFirstFocusableDescendant(element) {
	// The * means all.
	const descendents = element.getElementsByTagName('*');

	for (var i = 0; i < descendents.length; i++) {
		if (isFocusable(descendents[i]) === true) {
			return descendents[i];
		}
	}
}

/**
 * Recursively search a given element for its last focusable element
 * and set lastFocusableDescendant to that element.
 * @param element
 */
function getLastFocusableDescendant(element) {
	// The * means all.
	const descendents = element.getElementsByTagName('*');

	for (var i = descendents.length - 1; i >= 0; i--) {
		if (isFocusable(descendents[i]) === true) {
			return descendents[i];
		}
	}
}

function setEventsAndAnimation(dialog: Dialog, callback) {
	dialog.backdrop.classList.add('in');
	dialog.el.style.display = 'none';
	setTimeout(function () {
		dialog.backdrop.classList.remove('in');
		dialog.el.style.display = '';
		dialog.el.setAttribute('data-dialog-animation-name', `${dialog.options.animationName}In`);
		setTimeout(function () {
			dialog.el.setAttribute('data-dialog-animation-name', '');

			//dialog.firstFocusableDescendant.focus();
			dialog.el.querySelector<HTMLElement>('.dialogLabel').focus();

			document.removeEventListener('focus', trapFocus, true);
			document.addEventListener('focus', trapFocus, true);

			if (dialog.options.closeDialogOnEsc) {
				document.addEventListener('keydown', checkForEscapeKey, true);
			}

			// Close dialog when the close dialog action is clicked.
			dialog.el.querySelector('button.closeDialog').addEventListener('click', (event) => {
				close(dialog.id, dialog.focusAfterClosed);
			});

			if (dialog.options.closeDialogOnBackdropClick === true) {
				dialog.backdrop.addEventListener('click', (event) => {
					if (dialog.backdrop !== event.target) return;

					dialog.el.querySelector<HTMLElement>('button.closeDialog').click();
				});
			}

			if (typeof callback === 'function') {
				callback();
			}
		}, Animation.getSpeed(dialog.options.animationSpeed));
	}, Animation.getSpeed('veryFast'));
}

/* Utilities* */

/**
 * Check if a given element can be focused.
 * @param element
 */
function isFocusable(element) {
	if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
		return true;
	}

	if (element.disabled) {
		return false;
	}

	switch (element.nodeName) {
		case 'A':
			return !!element.href && element.rel != 'ignore';
		case 'INPUT':
			return element.type != 'hidden' && element.type != 'file';
		case 'BUTTON':
		case 'SELECT':
		case 'TEXTAREA':
			return true;
		default:
			return false;
	}
}

function getCurrentDialog() {
	return openDialogList[openDialogList.length - 1];
}

function getOpenDialogById(id) {
	for (var i = 0; i < openDialogList.length; i++) {
		if (openDialogList[i].id === id) {
			return openDialogList[i];
		}
	}
}

function removeOpenDialogById(id) {
	for (var i = 0; i < openDialogList.length; i++) {
		if (openDialogList[i].id === id) {
			openDialogList.splice(i, 1);
		}
	}
}

function getOpenDialogIndexById(id) {
	for (var i = 0; i < openDialogList.length; i++) {
		if (openDialogList[i].id === id) {
			return i;
		}
	}
}
